<template>
  <div class="details-class">
    <div class="public-div-border title">标题：{{ r.title }}</div>
    <div class="public-div-border time">时间：{{ r.time }}</div>
    <div class="public-div-border">
      <div class="public-text">提问内容：</div>
      <div class="public-label">
        {{ r.upcontent }}
      </div>
    </div>
    <div class="public-div-border">
      <div class="public-text">回复内容：</div>
      <div class="public-label">
        {{ r.retcontent?r.retcontent:'未回复' }}
      </div>
    </div>
    <i
      class="star-class"
      :class="icon ? 'el-icon-star-on' : 'el-icon-star-off'"
      @click="fabulous"
    ></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: false,
      r: {},
    };
  },
  mounted() {
    this.$axios.get("/appealapi/upbrowse.php?id=" + this.$route.query.id);
    this.$axios
      .get("/appealapi/getc.php?id=" + this.$route.query.id)
      .then((res) => {
        this.r = res.data;
      });
    this.$axios
      .get(
        "/appealapi/getfbulous.php?n=" +
          sessionStorage.getItem("username") +
          "&c=" +
          this.$route.query.id
      )
      .then((res) => {
        if (!res.data) {
          this.icon = false;
        } else {
          this.icon = true;
        }
      });
  },
  methods: {
    fabulous() {
      this.$axios
        .get(
          "appealapi/setfbulous.php?n=" +
            sessionStorage.getItem("username") +
            "&c=" +
            this.$route.query.id
        )
        .then((res) => {
          console.log(res.data);
          this.icon = !this.icon;
          if (this.icon) {
            this.$message({
              message: "点赞成功",
              type: "success",
            });
          } else {
            this.$message({
              message: "已取消点赞",
              type: "warning",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.details-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .title {
    font-size: 22px;
    font-weight: 900;
  }
  .time {
    font-size: 14px;
    color: #a9a9a9;
  }
  // .context {
  //   font-size: 18px;
  // }
  .star-class {
    font-size: 30px;
    font-weight: 900;
    margin: 50px 0px;
  }
}
</style>